<template>
  <LiefengContent>
    <template v-slot:title>服务项设置</template>
    <template v-slot:toolsbarRight>
      <Button type="primary" @click="addService" icon="md-add"
        >新增服务项</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :pageSize="pageSize"
      ></LiefengTable>

      <!-- 新增 /修改-->
      <LiefengModal
        :title="addChangeTitle"
        width="500px"
        height="250px"
        :value="addChangeStatus"
        @input="addChangeStatusFn"
        class="addModal"
      >
        <template v-slot:contentarea>
          <Form
            v-if="addChangeStatus"
            :model="addChangeForm"
            :label-width="115"
            ref="addChange"
            label-colon
            :rules="addChangeFormValidate"
          >
            <FormItem label="服务项" prop="name">
              <Input v-model.trim="addChangeForm.name" :maxlength='50'></Input>
            </FormItem>
            <FormItem label="所属服务类型" prop="type">
              <Select v-model="addChangeForm.type" :transfer="true">
                <Option value="1">养老服务评估</Option>
              </Select>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="addChangeStatusFn(false)"
            type="info"
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="saveAddChange">保存</Button>
        </template>
      </LiefengModal>
      <!-- 政策说明 -->
      <LiefengModal
        :fullscreen="true"
        :value="policyStatus"
        @input="policyStatusFn"
        class="policy"
      >
        <template v-slot:contentarea>
          <!-- <iframe
            name="ueditor"
            id="ueditor"
            src="/ueditor/index.html?titlename=政策说明"
            style="width: 100%; height: calc(100vh - 78px); border: 0px"
            @load="loadUeditor"
          ></iframe> -->
          <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=政策说明"
                 style="width: 100%; height: calc(100vh - 78px); border: 0px"
                 @load="loadUeditor"
              ></iframe>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="
              cancelModelTip = true;
              policyStatusFn(false);
            "
            type="info"
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="savePolicy">保存</Button>
        </template>
      </LiefengModal>

      <!-- 办理指引 -->
      <LiefengModal
        title="办理服务指引"
        :fullscreen="true"
        :value="pointStatus"
        @input="pointStatusFn"
        class="policy"
      >
        <template v-slot:contentarea>
          <Tabs value="1">
            <TabPane label="小程序" name="1">
              <Form :model="pointForm1" :label-colon="true" :label-width="120">
                <FormItem label="配置跳转内容">
                  <Select
                    v-model="pointForm1.jumpType"
                    style="width: 300px"
                    :transfer="true"
                    @on-change="changejumpType1"
                  >
                    <Option value="1">小程序</Option>
                    <Option value="2">链接</Option>
                    <Option value="3">申请流程</Option>
                  </Select>
                </FormItem>
                <FormItem label="APPID" v-if="pointForm1.jumpType == 1">
                  <Input
                    v-model.trim="pointForm1.appid"
                    :maxlength='50'
                    style="width: 300px"
                  ></Input>
                </FormItem>
                <FormItem
                  label="小程序跳转链接"
                  v-if="pointForm1.jumpType == 1"
                >
                  <Input v-model.trim="pointForm1.href" style="width: 500px" :maxlength='50'></Input>
                </FormItem>
                <FormItem label="跳转链接" v-if="pointForm1.jumpType == 2">
                  <Input v-model.trim="pointForm1.href" style="width: 500px" :maxlength='50'></Input>
                </FormItem>
                <div v-if="pointForm1.jumpType == 3">
                  <!-- <iframe
                    name="ueditor"
                    id="ueditor1"
                    src="/ueditor/index.html?titlename=服务指引内容"
                    style="
                      width: 100%;
                      height: calc(100vh - 238px);
                      border: 0px;
                    "
                    @load="loadUeditor1"
                  ></iframe> -->
                  <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=服务指引内容"
                 style="
                      width: 100%;
                      height: calc(100vh - 238px);
                      border: 0px;
                    "
                    @load="loadUeditor1"
              ></iframe>
                </div>
              </Form>
            </TabPane>
            <TabPane label="App" name="2">
              <Form :model="pointForm2" :label-colon="true" :label-width="120">
                <FormItem label="配置跳转内容">
                  <Select
                    v-model="pointForm2.jumpType"
                    style="width: 300px"
                    :transfer="true"
                    @on-change="changejumpType2"
                  >
                    <Option value="2">链接</Option>
                    <Option value="3">申请流程</Option>
                  </Select>
                </FormItem>
                <FormItem label="跳转链接" v-if="pointForm2.jumpType == 2">
                  <Input v-model.trim="pointForm2.href" style="width: 500px" :maxlength='50'></Input>
                </FormItem>
                <div v-if="pointForm2.jumpType == 3">
                  <!-- <iframe
                    name="ueditor"
                    id="ueditor2"
                    src="/ueditor/index.html?titlename=服务指引内容"
                    style="
                      width: 100%;
                      height: calc(100vh - 238px);
                      border: 0px;
                    "
                    @load="loadUeditor2"
                  ></iframe> -->
                  <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=服务指引内容"
                 style="
                      width: 100%;
                      height: calc(100vh - 238px);
                      border: 0px;
                    "
                    @load="loadUeditor2"
              ></iframe>
                </div>
              </Form>
            </TabPane>
          </Tabs>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="
              cancelModelTip = true;
              pointStatusFn(false);
            "
            type="info"
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="savePoint">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/pensionserviceoption')
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      pageSize: 20,
      page: 1,
      total: 0,
      loading: false,
      tableData: [],
      talbeColumns: [
        {
          title: "服务项名称",
          key: "name",
          minWidth: 150,
          align: "center",
        },
        {
          title: "所属服务类型",
          key: "type",
          minWidth: 150,
          align: "center",
        },
        {
          title: "创建人",
          key: "creator",
          width: 150,
          align: "center",
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          minWidth: 150,
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          width: 130,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("i-switch", {
                props: {
                  value: params.row.status,
                  size: "large",
                  "true-value": "1",
                  "false-value": "2",
                  "before-change": this.beforeChangeSwitch,
                },
                scopedSlots: {
                  open: () => h("span", "启用"),
                  close: () => h("span", "禁用"),
                },
                on: {
                  "on-change": (val) => {
                    this.changeSwitch(val, params.row.guideItemId);
                  },
                },
              }),
            ]);
          },
        },
        {
          title: "操作",
          width: 300,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.infoId = params.row.guideItemId;
                      this.policyFn();
                    },
                  },
                },
                "政策说明"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "success",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.infoId = params.row.guideItemId;
                      this.pointFn();
                    },
                  },
                },
                "办理指引"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "warning",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.infoId = params.row.guideItemId;
                      this.changePolicy();
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: "温馨提示",
                        content:
                          "您正在删除服务项信息，此操作不可逆，是否继续？",
                        onOk: () => {
                          this.$post(
                            "/pension/api/pc/elderly/guideItem/deleteById",
                            {
                              guideItemId: params.row.guideItemId,
                            }
                          ).then((res) => {
                            if (res.code == 200) {
                              this.$Message.success({
                                background: true,
                                content: "删除成功",
                              });
                              this.hadlePageSize({
                                page: this.page,
                                pageSize: this.pageSize,
                              });
                            } else {
                              this.$Message.error({
                                background: true,
                                content: red.desc,
                              });
                            }
                          });
                        },
                      });
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      //   新增服务项
      addChangeStatus: false,
      addChangeTitle: "",
      addChangeForm: {},
      addChangeFormValidate: {
        name: [{ required: true, message: "请输入服务项", trigger: "blur" }],
        type: [
          { required: true, message: "请选择所属服务类型", trigger: "change" },
        ],
      },
      // 政策说明
      policyStatus: false,
      cancelModelTip: false,
      policyDesc: "",
      infoId: "",

      //   办理指引
      pointStatus: false,
      pointForm1: { jumpType: "1" },
      pointForm2: { jumpType: "2" },
    };
  },
  methods: {
    //   办理指引
    savePoint() {
      if (this.pointForm1.jumpType == "3") {
        var v_Obj = document.getElementById("ueditor1").contentWindow; //获取iframe对象
        this.pointForm1.content = v_Obj.getContent(); //获取富文本内容
      }
      if (this.pointForm2.jumpType == "3") {
        var v_Obj = document.getElementById("ueditor2").contentWindow; //获取iframe对象
        this.pointForm2.content = v_Obj.getContent(); //获取富文本内容
      }
      let params = [
        {
          clientType: "1",
          content: this.pointForm1.content,
          guideItemId: this.infoId,
          id: this.pointForm1.id, //修改回显的id
          href: this.pointForm1.href,
          jumpType: this.pointForm1.jumpType,
          paraJson: JSON.stringify({
            appid: this.pointForm1.appid,
          }),
        },
        {
          clientType: "2",
          id: this.pointForm2.id, //修改回显的id
          content: this.pointForm2.content,
          guideItemId: this.infoId,
          href: this.pointForm2.href,
          jumpType: this.pointForm2.jumpType,
        },
      ];
      this.$post(
        "/pension/api/pc/elderly/guideItem/setInstruction",
        {
          guideItemConfigRoList: params,
        },
        { "Content-Type": "application/json" }
      )
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "保存成功",
            });
            this.cancelModelTip = false;
            this.pointStatus = false;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "保存失败，请重试",
          });
        });
    },
    changejumpType1(val) {
      this.pointForm1 = {
        jumpType: val,
        content: '',
        id: this.pointForm1.id
      };
    },
    changejumpType2(val) {
      this.pointForm2 = {
        jumpType: val,
        content: '',
        id: this.pointForm2.id
      };
    },

    pointStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.pointStatus = status;
          },
        });
      } else {
        this.pointStatus = status;
      }
    },
    loadUeditor1() {
      //小程序
      var v_Obj = document.getElementById("ueditor1").contentWindow; //获取iframe对象
      v_Obj.setContent(this.pointForm1.content); //写入编辑器富文本内容
    },
    loadUeditor2() {
      //app
      var v_Obj = document.getElementById("ueditor2").contentWindow; //获取iframe对象
      v_Obj.setContent(this.pointForm2.content); //写入编辑器富文本内容
    },
    pointFn() {
      this.$Message.loading({
        content: "正在获取数据，请稍等...",
        duration: 0,
      });
      this.pointForm1 = { jumpType: "1" };
      this.pointForm2 = { jumpType: "2" };
      this.$get("/pension/api/pc/elderly/guideItem/getInstruction", {
        guideItemId: this.infoId,
      }).then((res) => {
        this.$Message.destroy();
        if (res.code == 200 && res.dataList) {
          res.dataList.map((item) => {
            if (item.clientType == "1") {
              //小程序
              this.pointForm1 = {
                id: item.id,
                content: item.content,
                href: item.href,
                jumpType: item.jumpType,
                appid: item.paraJson ? JSON.parse(item.paraJson).appid : "",
              };
            } else if (item.clientType == "2") {
              //app
              this.pointForm2 = {
                id: item.id,
                content: item.content,
                href: item.href,
                jumpType: item.jumpType,
              };
            }
          });

          this.pointStatus = true;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    //   切换状态
    changeSwitch(val, guideItemId) {
      this.$post("/pension/api/pc/elderly/guideItem/updateStatus", {
        guideItemId: guideItemId,
        status: val,
      }).then((res) => {
        if (res.code != 200) {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    beforeChangeSwitch() {
      return new Promise((resolve) => {
        this.$Modal.confirm({
          title: "切换确认",
          content: "您确认要切换状态吗？",
          onOk: () => {
            resolve();
          },
        });
      });
    },
    //   政策说明
    policyFn() {
      this.$Message.loading({
        content: "正在获取数据，请稍等...",
        duration: 0,
      });

      this.$get("/pension/api/pc/elderly/guideItem/getPolicyDesc", {
        guideItemId: this.infoId,
      }).then((res) => {
        this.$Message.destroy();
        if (res.code == 200 && res.data) {
          this.policyDesc = res.data.policyDesc != null ? res.data.policyDesc : '';
          this.loadUeditor();
          this.policyStatus = true;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    savePolicy() {
      var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
      this.policyDesc = v_Obj.getContent(); //获取富文本内容
      if (this.policyDesc == "") {
        this.$Message.error({
          background: true,
          content: "请填写政策说明",
        });
        return;
      }
      this.$post(
        "/pension/api/pc/elderly/guideItem/setContent",
        {
          guideItemId: this.infoId,
          policyDesc: this.policyDesc,
        },
        { "Content-Type": "application/json" }
      ).then((res) => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: "保存成功",
          });
          this.cancelModelTip = false;
          this.policyStatus = false;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    loadUeditor() {
      var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
      v_Obj.setContent(this.policyDesc); //写入编辑器富文本内容
    },
    policyStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.policyStatus = status;
          },
        });
      } else {
        this.policyStatus = status;
      }
    },
    //   新增/修改
    addChangeStatusFn(status) {
      this.addChangeStatus = status;
    },
    changePolicy() {
      this.$Message.loading({
        content: "正在获取数据，请稍等...",
        duration: 0,
      });
      this.$get("/pension/api/pc/elderly/guideItem/getContents", {
        guideItemId: this.infoId,
      }).then((res) => {
        this.$Message.destroy();
        if (res.code == 200 && res.data) {
          this.addChangeForm.name = res.data.name;
          this.addChangeForm.type = res.data.type;
          this.addChangeTitle = "修改服务项";
          this.addChangeStatus = true;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    saveAddChange() {
      this.$refs.addChange.validate((status) => {
        if (status) {
          this.$post("/pension/api/pc/elderly/guideItem/addGuideItem", {
            ...this.addChangeForm,
            oemCode: parent.vue.oemInfo.oemCode,
            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          }).then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "保存成功",
              });
              this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
              });
              this.addChangeStatus = false;
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          });
        }
      });
    },
    addService() {
      this.addChangeTitle = "新增服务项";
      this.addChangeForm = {};
      this.addChangeStatus = true;
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/pension/api/pc/elderly/guideItem/queryByPage", {
        page: obj.page,
        pageSize: obj.pageSize,
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              item.gmtCreate = this.$core.formatDate(
                new Date(item.gmtCreate),
                "yyyy-MM-dd hh:mm:ss"
              );
              item.type = item.type == "1" ? "养老服务评估" : "";
            });
            this.tableData = res.dataList;
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试",
          });
        });
    },
  },
  created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
.policy {
  /deep/.ivu-icon-ios-close {
    display: none;
  }
}
</style>